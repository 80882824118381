import { css } from '@emotion/react';
import { Button, ListItemButton, styled } from '@mui/joy';

const TempStyledListItemButton = styled(Button)(
  ({ theme }) => css`
    border: none;
    border-radius: ${theme.spacing(2.5)};
    font-weight: bold;
    padding: ${theme.spacing(1)};
    background-color: ${theme.palette.common.white};
    color: ${theme.vars.palette.primary.plainColor};

    &:hover {
      background-color: ${theme.vars.palette.primary.plainColor};
      color: ${theme.palette.common.white};
    }
  `,
);

TempStyledListItemButton.defaultProps = {
  color: 'primary',
  role: 'menuitem',
};

export const HeaderListItemButton = TempStyledListItemButton as typeof ListItemButton;
