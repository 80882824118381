import { extendTheme } from '@mui/joy';

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '50': '#e1f0fa',
          '100': '#b5d9f2',
          '200': '#84bfea',
          '300': '#52a5e2',
          '400': '#2d92db',
          '500': '#087fd5',
          '600': '#0777d0',
          '700': '#066cca',
          '800': '#0462c4',
          '900': '#024fba',
        },
      },
    },
  },
});
