import { css } from '@emotion/react';
import { Box, List, ListItem, styled } from '@mui/joy';
import { Link, useLocation } from '@tanstack/react-router';

import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';

const links = [
  {
    title: 'Über das Projekt',
    href: '/ueber',
  },
  {
    title: 'Kontakt',
    href: '/kontakt',
  },
  {
    title: 'Impressum',
    href: '/impressum',
  },
  {
    title: 'Datenschutz',
    href: '/datenschutz',
  },
];

const Root = styled(Box)(({ theme }) => {
  const location = useLocation();
  const isIndex = location.pathname === '/';

  return css`
    display: flex;
    gap: 1.5rem;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    bottom: 0;
    left: 0;
    padding-top: ${theme.spacing(1)};
    backdrop-filter: blur(2px);
    border-top-right-radius: 10px;
    flex: 1;

    ul {
    }

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      ${isIndex ? 'position: fixed;' : 'position: absolute'}
      width: max-content;
      padding: 0 ${theme.spacing(2)};
    }
  `;
});

const StyledLink = styled(Link)(
  ({ theme }) => css`
    font-size: ${theme.vars.fontSize.md};
    color: inherit;
    text-decoration: none;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      font-size: ${theme.vars.fontSize.xs};
    }
  `,
);

const StyledList = styled(List)(
  ({ theme }) => css`
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(1)};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      flex-direction: row;
      padding: 0;
    }
  `,
);

export function Footer() {
  return (
    <Root component="footer">
      <StyledList role="menubar">
        {links.map(({ title, href }) => (
          <ListItem sx={{ padding: 0 }} role="none" key={title}>
            <StyledLink to={href} role="menuitem">
              {title}
            </StyledLink>
          </ListItem>
        ))}
      </StyledList>
    </Root>
  );
}
