import { css } from '@emotion/react';
import { List, styled } from '@mui/joy';

export const HeaderList = styled(List)(
  ({ theme }) => css`
    --ListItem-minHeight: ${theme.spacing(5)};
    --ListItem-paddingX: ${theme.spacing(2)};
    --ListItem-paddingY: 0;
    --ListDivider-gap: 0;
    gap: ${theme.spacing(3)};
    align-items: center;
  `,
);
HeaderList.defaultProps = {
  role: 'menubar',
  orientation: 'horizontal',
};
